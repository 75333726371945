import { PlusIcon } from '@heroicons/react/24/outline';
import emoji from 'emoji-dictionary';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Tooltip from '@radix-ui/react-tooltip';

const user = {
  name: 'Rebecca Nicholas',
  role: 'Product Designer',
  imageUrl:
    'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const stats = [
  { label: 'Current Plan', value: 'Trial' },
  { label: 'Blanace Credits', value: 10000},
  { label: 'Personal days left', value: 2 },
]

export default function ScraperHero({dashboard, toggleOpen}) {
  const [tooltipContent, setTooltipContent] = useState("Click to copy!");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(dashboard.token);
    setTooltipContent("Copied!");
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipContent("Click to copy!");
      setTooltipOpen(false);
    }, 1500);
  };

  return (
    <div className="rounded-lg mb-10 bg-white overflow-hidden shadow mt-5">
      <h2 className="sr-only" id="profile-overview-title">
        Unwrangle No-code Scrapers
      </h2>
      <div className="bg-white p-6">
        <div className="sm:flex sm:items-end sm:justify-between">
          <div className="sm:flex sm:space-x-5 max-w-[600px]">
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-sm font-medium text-gray-600">No-Code Data Extraction</p>
              <p className="text-xl font-bold text-gray-900 sm:text-2xl">Scrapers Dashboard</p>
              <p className="text-sm text-gray-600">Create a job to extract data from the website you want. Select relevant platform name for the scraper you want to use. Results will be available as CSV when the job is complete. </p>
              
            </div>
          </div>
          <div className="mt-5 sm:mt-0 flex flex-col items-end">
            <button
              className="flex justify-center items-center px-4 py-2 border border-myviolet shadow-sm text-sm font-medium rounded-md text-white bg-mypurple hover:bg-myorange hover:border-myorange"
              onClick={(e) => { toggleOpen(); }}
            >
              Create Job
              <PlusIcon className='h-5 w-5 ml-2' />
            </button>
            {dashboard.plan_name === 'Trial' && (
              <Link to='/billing' className="mt-2 text-mypurple hover:text-myorange text-sm underline">
                Upgrade Plan
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
        <div className="px-6 py-5 text-sm font-medium text-center">
          <span className="text-gray-600">Current Plan</span> : <span className="text-gray-900">{dashboard.plan_name}</span>
        </div>
        <div className="px-6 py-5 text-sm font-medium text-center">
          <span className="text-gray-600">Balance Credits</span> : <span className="text-gray-900">
            {dashboard.credits.amt} {emoji.getUnicode("coin")}
          </span>
        </div>
        <div className="px-6 py-5 text-sm font-medium text-center">
          <span className="text-gray-600">Expiring In</span> : <span className="text-gray-900">
            {dashboard.credits.days_left ? `${dashboard.credits.days_left} days` : 'Never'}
          </span>
        </div>
      </div>
    </div>
  )
}