/* This example requires Tailwind CSS v2.0+ */
const stats = [
  { name: 'Total Subscribers', stat: '71,897' },
  { name: 'Avg. Open Rate', stat: '58.16%' },
  { name: 'Avg. Click Rate', stat: '24.57%' },
]

export default function InfoCard({name, value, passClass, action, isBlurText}) {
  return (
    <div className={`${passClass} px-4 py-5 bg-white shadow rounded-lg sm:p-6 mb-4 ld:mb-0 mr-4`}>
      <div name={name}>
        <dt className="text-sm font-medium text-gray-500">{name}</dt>
        <dd className={`mt-1 text-sm sm:text-lg font-bold sm:font-semibold text-gray-900 ${isBlurText ? 'blur-sm hover:blur-none' : ''}`}>
          {value}
        </dd>
      </div>
      {action && action}
    </div>
  )
}
