import React from 'react';
import ReactDOM from 'react-dom';
import posthog from 'posthog-js';
import App from './App';
import './index.css'
import '@radix-ui/themes/styles.css';
import reportWebVitals from './reportWebVitals';

import "@fontsource/inter";

// Initialize PostHog
posthog.init('phc_UWtsk36dyCwhC3RJrtzFicnGoivgogIw1knFyytrG6G', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only'
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();