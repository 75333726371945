import { Link } from 'react-router-dom';
import React from 'react';

import { Linkedin, Twitter } from 'react-feather';

const Footer = () => (
  <footer className="bg-white">
    <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 md:flex md:justify-between lg:px-8">
      <div className="flex">
        <a href='https://www.unwrangle.com/privacy' target='_blank'><p className='text-center text-base text-gray-400 mr-5'>Privacy Policy</p></a>
        <a href='https://www.unwrangle.com/tos' target='_blank'><p className='text-center text-base text-gray-400'>Terms of Service</p></a>
      </div>
      <div className="mt-8 md:mt-0">
        <div className="flex">
          <p className="text-center text-base text-gray-400 mr-5">
            &copy; {new Date().getFullYear()} Unwrangle.com. All rights reserved. 
          </p>
          <a target='_blank' href='https://twitter.com/unwranglehq' className='mr-5'>
            <Twitter className='text-gray-400' size={22} />
          </a>
          <a target='_blank' href='https://linkedin.com/company/unwrangle' className='mr-5'>
            <Linkedin className='text-gray-400' size={22} />
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer;