import { Link } from 'react-router-dom';
import { CheckCircleIcon, XIcon } from '@heroicons/react/24/solid';

export default function SuccessAlert({msg, link=null}) {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm font-medium text-green-800">{msg}</p>
          {link && (
            <p className="mt-3 text-sm md:mt-0 md:ml-6">
              <Link to={link['path']} className="whitespace-nowrap font-medium text-green-800 hover:text-myorange">
                {link['text']} <span aria-hidden="true">&rarr;</span>
              </Link>
            </p>
          )}
        </div>
        {/* <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div> */}
      </div>
    </div>
  )
}