import React, { useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { anOldHope } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { PlayIcon, PauseIcon, ClipboardDocumentIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';

const domain = process.env.REACT_APP_SERVER_URL;

const platformOptions = {
    'amazon': 'Amazon',
    'bestbuy': 'Best Buy',
    'costco': 'Costco',
    'homedepot': 'The Home Depot',
    'wayfair': 'Wayfair',
    'lowes': 'Lowes.com',
    'samsclub': 'Sam\'s Club',
    // 'shopee': 'Shopee',
    'yelp': 'Yelp',
    'target': 'Target.com',
    'mercado': 'Mercado Livre Brazil',
}

const typeOptions = {
    'search': 'Search Results',
    'detail': 'Product Page',
    'reviews': 'Reviews'
}

const typeOptionsHomeDepot = {
    'search': 'Search Results',
    'detail': 'Product Page',
    'reviews': 'Reviews'
}

const typeOptionsWayfair = {
    'detail': 'Product Page',
}

const typeOptionsCostco = {
    'search': 'Search Results',
    'reviews': 'Reviews'
}

const typeOptionsMercado = {
    'search': 'Search Results',
    'detail': 'Product Page',
    'sellers': 'Sellers / Offers'
}

const typeOptionsYelp = {
  'search': 'Search Results',
  'reviews': 'Reviews'
}

const countryOptions = {
  'us': 'USA',
  'ca': 'Canada',
  'gb': 'UK',
  'fr': 'France',
  'de': 'Germany',
  'es': 'Spain',
  'jp': 'Japan',
  'au': 'Australia',
  'in': 'India',
  'br': 'Brazil',
};

export default function PlaygroundForm({apiKey, refreshCredits}) {
  const [errors, setErrors] = useState({});
  const [platform, setPlatform] = useState('amazon');
  const [type, setType] = useState('search');
  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('San Francisco, CA');
  const [country, setCountry] = useState('us');
  const [page, setPage] = useState(1);
  const [productURL, setProductURL] = useState('');
  const [itemId, setItemId] = useState('');
  const [bizId, setBizId] = useState('');
  const [asin, setAsin] = useState('');
  const [countryCode, setCountryCode] = useState('us');
  const [requestURL, setRequestURL] = useState('');
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [response, setResponse] = useState('');
  const [showCheckIconRequest, setShowCheckIconRequest] = useState(false);
  const [showCheckIconResponse, setShowCheckIconResponse] = useState(false);

  const handlePlatformChange = (event) => {
    setPlatform(event.target.value);
  }

  const handleTypeChange = (event) => {
    setType(event.target.value);
  }

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  }

  const handleProductURLChange = (event) => {
    try {
      const url = new URL(event.target.value);
      setProductURL(url.origin + url.pathname);
      setErrors(prev => ({ ...prev, productURL: null }));
    } catch (error) {
      setProductURL(event.target.value);
      setErrors(prev => ({ ...prev, productURL: 'Invalid URL' }));
    }
  }

  const handleItemIdChange = (event) => {
    setItemId(event.target.value);
  }

  const handleBizIdChange = (event) => {
    setBizId(event.target.value);
  }

  const handleAsinChange = (event) => {
    setAsin(event.target.value);
  }

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  }

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  }

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  }

  const handlePageChange = (event) => {
    setPage(event.target.value);
  }

  const getTypeOptions = (platform) => {
    switch(platform) {
      case 'homedepot':
        return typeOptionsHomeDepot;
      case 'mercado':
        return typeOptionsMercado;
      case 'wayfair':
        return typeOptionsWayfair;
      case 'costco':
        return typeOptionsCostco;
      case 'yelp':
        return typeOptionsYelp;
      default:
        return typeOptions;
    }
  }

  const genRequestURL = (platform, type, keyword, productURL, country, location, page, itemId, bizId, asin, countryCode, apiKey) => {
    if (type === 'search') {
      if (platform === 'yelp') {
        return `${domain}/api/getter/?platform=${platform}_${type}&search=${keyword}&location=${location}&page=${page}&api_key=${apiKey}`;
      } else if (platform === 'amazon') {
        return `${domain}/api/getter/?platform=${platform}_${type}&search=${keyword}&country_code=${country}&page=${page}&api_key=${apiKey}`;
      } else {
        return `${domain}/api/getter/?platform=${platform}_${type}&search=${keyword}&page=${page}&api_key=${apiKey}`;
      }
    } else if (type === 'reviews') {
      if (platform === 'yelp') {
        return `${domain}/api/getter/?platform=${platform}_${type}&biz_id=${bizId}&page=${page}&api_key=${apiKey}`;
      } else if (platform === 'amazon') {
        if (productURL) {
          return `${domain}/api/getter/?platform=${platform}_${type}&url=${encodeURIComponent(productURL)}&page=${page}&api_key=${apiKey}`;
        } else {
          return `${domain}/api/getter/?platform=${platform}_${type}&asin=${asin}&country_code=${countryCode}&page=${page}&api_key=${apiKey}`;
        }
      } else if (platform === 'homedepot') {
        return `${domain}/api/getter/?platform=${platform}_${type}&${itemId ? `item_id=${itemId}` : `url=${encodeURIComponent(productURL)}`}&page=${page}&api_key=${apiKey}`;
      } else {
        return `${domain}/api/getter/?platform=${platform}_${type}&url=${encodeURIComponent(productURL)}&page=${page}&api_key=${apiKey}`;
      }
    } else if (type === 'sellers') {
      return `${domain}/api/getter/?platform=${platform}_${type}&url=${encodeURIComponent(productURL)}&page=${page}&api_key=${apiKey}`;
    } else if (type === 'detail') {
      if (platform === 'amazon') {
        if (productURL) {
          return `${domain}/api/getter/?platform=${platform}_${type}&url=${encodeURIComponent(productURL)}&api_key=${apiKey}`;
        } else {
          return `${domain}/api/getter/?platform=${platform}_${type}&asin=${asin}&country_code=${countryCode}&api_key=${apiKey}`;
        }
      } else if (platform === 'homedepot') {
        return `${domain}/api/getter/?platform=${platform}_${type}&${itemId ? `item_id=${itemId}` : `url=${encodeURIComponent(productURL)}`}&api_key=${apiKey}`;
      } else {
        return `${domain}/api/getter/?platform=${platform}_${type}&url=${encodeURIComponent(productURL)}&api_key=${apiKey}`;
      }
    }
  }

  useEffect(() => {
    setRequestURL(genRequestURL(platform, type, keyword, productURL, country, location, page, itemId, bizId, asin, countryCode, apiKey));
  }, [platform, type, keyword, productURL, page, country, location, itemId, bizId, asin, countryCode, apiKey])

  useEffect(() => {
    if (platform === 'wayfair') {
      setType('detail');
    } else {
      setType('search'); // Default type for all platforms
    }
    setKeyword('');
    setLocation('San Francisco, CA');
    setCountry('us');
    setPage(1);
    setProductURL('');
    setItemId('');
    setBizId('');
    setAsin('');
    setCountryCode('us');
    setErrors({});
  }, [platform]);

  const handleSubmit = async (event) => {
    setIsLoadingRequest(true);
    setResponse('');
    const res = await fetch(requestURL).then(res => res.json());
    setIsLoadingRequest(false);
    setResponse(res);
    refreshCredits();
  }

  return (
    <div>
      <h3 className="section-heading">API Playground</h3>
      <div className='px-4 py-5 bg-white shadow rounded-lg my-3 flex flex-wrap'>
        <div className='flex-1 p-2 sm:border-r-2 w-full lg:w-1/2 mb-4 lg:mb-0'>
          <form className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Select an API</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Make test requests to any of our APIs with the form below</p>
                </div>
                <div className="space-y-6 sm:space-y-5">
                  {/* Platform selection */}
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="platform" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                      Select Platform
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select 
                        id="platform"
                        name="platform" 
                        value={platform} 
                        onChange={handlePlatformChange} 
                        className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        {Object.keys(platformOptions).map((option, i) => (<option value={option} key={i}>{platformOptions[option]}</option>))}
                      </select>
                    </div>
                  </div>

                  {/* Data Type selection */}
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="dataType" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                      Select Data Type
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select 
                        id="dataType"
                        name="dataType" 
                        value={type} 
                        onChange={handleTypeChange} 
                        className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        {Object.keys(getTypeOptions(platform)).map((option, i) => (<option value={option} key={i}>{getTypeOptions(platform)[option]}</option>))}
                      </select>
                      {platform === 'amazon' && type === 'detail' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Please note that sponsored listings' urls will not work with the Amazon Product Data API.  
                        </p>
                      )}
                      {platform === 'amazon' && type === 'reviews' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/amazon-reviews-scraper" target="_blank">Amazon Reviews Scraper</a> to get all reviews for a product with a single request.
                        </p>
                      )}
                      {platform === 'mercado' && type === 'search' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/mercado-livre-search-scraper" target="_blank">Mercado Livre Search Scraper</a> to get all search results for a keyword with a single request.
                        </p>
                      )}
                      {platform === 'mercado' && type === 'sellers' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/mercado-livre-price-offers-scraper" target="_blank">Mercado Livre Price Offers Scraper</a> to get all seller / price offers for a listing.
                        </p>
                      )}
                      {platform === 'mercado' && type === 'detail' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/mercado-livre-product-scraper" target="_blank">Mercado Livre Product Scraper</a> to get product details for multiple listings at once.
                        </p>
                      )}
                      {platform === 'wayfair' && type === 'detail' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/wayfair-product-scraper" target="_blank">Wayfair Product Scraper</a> if you also want to get specs and dimensions for each product.
                        </p>
                      )}
                      {platform === 'yelp' && type === 'reviews' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/yelp-reviews-scraper" target="_blank">Yelp Reviews Scraper</a> to get all the reviews for a business with a single request.
                        </p>
                      )}
                      {platform == 'homedepot' && type == 'reviews' && (
                        <p className="mt-2 text-sm text-gray-500">
                          Use the <a className='text-mypurple hover:text-myorange' href="https://www.unwrangle.com/scrapers/homedepot-reviews-scraper" target="_blank">Home Depot Reviews Scraper</a> to get all reviews for a product at once.
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Search Keyword input */}
                  {type === 'search' && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="keyword" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        Search Keyword
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          id="keyword"
                          name="keyword"
                          type="text"
                          value={keyword}
                          autoComplete="keyword"
                          onChange={handleKeywordChange}
                          className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  )}

                  {/* Product URL or Item ID input */}
                  {(type === 'detail' || type === 'reviews' || type === 'sellers') && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="productURL" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        {platform === 'yelp' && type === 'reviews' ? 'Biz ID' : 'Product URL'}
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        {platform === 'yelp' && type === 'reviews' ? (
                          <input
                            id="bizId"
                            name="bizId"
                            type="text"
                            value={bizId}
                            autoComplete="bizId"
                            onChange={handleBizIdChange}
                            className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        ) : (
                          <input
                            id="productURL"
                            name="productURL"
                            type="text"
                            value={productURL}
                            autoComplete="productURL"
                            onChange={handleProductURLChange}
                            className={`block w-full sm:w-60 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${errors.productURL ? 'border-red-500' : 'border-gray-300'}`}
                          />
                        )}
                        {platform === 'yelp' && type === 'reviews' && (
                          <p className="mt-2 text-sm text-gray-500 pr-10">
                            Note: You can get the "biz id" for any listing using the Yelp Search API. 
                          </p>
                        )}
                        <p className="mt-2 text-sm text-gray-500 pr-10">
                          Note: please ensure that product URL does not contain any query parameters.
                        </p>
                        {errors.productURL && <p className="mt-2 text-sm text-red-500">{errors.productURL}</p>}
                      </div>
                    </div>
                  )}

                  {/* Item ID input for Home Depot */}
                  {platform === 'homedepot' && (type === 'detail' || type === 'reviews') && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="itemId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        Item ID (optional)
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          id="itemId"
                          name="itemId"
                          type="text"
                          value={itemId}
                          autoComplete="itemId"
                          onChange={handleItemIdChange}
                          className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        <p className="mt-2 text-sm text-gray-500 pr-10">
                          Note: You can provide either URL or Item ID for Home Depot detail and reviews.
                        </p>
                      </div>
                    </div>
                  )}

                  {/* Location input for Yelp */}
                  {(platform === 'yelp' && type === 'search') && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="location" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        Location
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          id="location"
                          name="location"
                          type="text"
                          value={location}
                          autoComplete="location"
                          onChange={handleLocationChange}
                          className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  )}

                  {/* Country selection for Amazon */}
                  {(platform === 'amazon' && type === 'search') && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        Country
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select 
                          id="country"
                          name="country" 
                          value={country} 
                          onChange={handleCountryChange} 
                          className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                        >
                          {Object.keys(countryOptions).map((option, i) => (<option value={option} key={i}>{countryOptions[option]}</option>))}
                        </select>
                      </div>
                    </div>
                  )}

                  {(type === 'search' || type === 'reviews' || type === 'sellers') && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label htmlFor="page" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                        Page
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          id="page"
                          name="page"
                          type="number"
                          min="1"
                          value={page}
                          autoComplete="page"
                          onChange={handlePageChange}
                          className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  )}

                  {/* ASIN and Country Code inputs for Amazon detail and reviews */}
                  {(platform === 'amazon' && (type === 'detail' || type === 'reviews')) && (
                    <>
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="asin" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                          ASIN
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <input
                            id="asin"
                            name="asin"
                            type="text"
                            value={asin}
                            autoComplete="asin"
                            onChange={handleAsinChange}
                            className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          <p className="mt-2 text-sm text-gray-500 pr-10">
                            Note: You can provide either Product URL or ASIN + Country Code for Amazon detail and reviews.
                          </p>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="countryCode" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-2 sm:pb-0">
                          Country Code
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <select
                            id="countryCode"
                            name="countryCode"
                            value={countryCode}
                            onChange={handleCountryCodeChange}
                            className="block w-full sm:w-60 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                          >
                            {Object.keys(countryOptions).map((option, i) => (
                              <option value={option} key={i}>
                                {countryOptions[option]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <p className="pt-3 text-sm text-gray-500 whitespace-normal overflow-auto break-words">
                Requesting, <span className="mono text-mypurple">{requestURL}</span>
              </p>
            </div>

            <div className="pt-5">
              <div className="flex">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-mypurple py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-myorange focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handleSubmit}
                >
                  <p className='sm:mt-0.5'>Send Request </p>
                  {isLoadingRequest ? (
                    <PauseIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  ) : (
                    <PlayIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  )}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center ml-3 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:bg-mypurple"
                  onClick={() => {
                    navigator.clipboard.writeText(requestURL);
                    setShowCheckIconRequest(true);
                    setTimeout(() => setShowCheckIconRequest(false), 5000);
                  }}
                >
                  <p className='sm:mt-0.5'>Copy Request</p>
                  {showCheckIconRequest ? (
                    <CheckIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  ) : (
                    <ClipboardDocumentIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  )}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center ml-3 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:bg-mypurple"
                  onClick={() => {
                    navigator.clipboard.writeText(JSON.stringify(response, null, 2));
                    setShowCheckIconResponse(true);
                    setTimeout(() => setShowCheckIconResponse(false), 5000);
                  }}
                >
                  <p className='sm:mt-0.5'>Copy Response</p>
                  {showCheckIconResponse ? (
                    <CheckIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  ) : (
                    <ClipboardDocumentListIcon className='h-8 w-8 sm:h-4 sm:w-4 m-auto sm:mx-2 sm:mt-1' />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className='flex-1 p-2 w-full lg:w-1/2'>
          <div className='bg-black shadow rounded-lg h-full max-w-full sm:max-w-2xl max-h-screen p-2 overflow-x-auto overflow-y-auto min-h-[300px]'>
            {isLoadingRequest && <p className='text-white font-medium'>Loading...</p>}
            {response && (
              <div className='p-2'>
                <SyntaxHighlighter 
                  language="json" 
                  style={anOldHope}
                >
                  {JSON.stringify(response, null, 2)}
                </SyntaxHighlighter>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}