import { Link } from 'react-router-dom';
import { ExclamationIcon } from '@heroicons/react/solid'

export default function ErrorAlert({msg, link=null}) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        {typeof(msg) === 'string' || msg.length === 1 ? (
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <div className="text-sm text-red-700">
              <p>
                {msg}
              </p>
            </div>
              {link && (
                <p className="mt-3 text-sm md:mt-0 md:ml-6">
                  <Link to={link['path']} className="whitespace-nowrap font-medium text-red-400 hover:text-myorange">
                    {link['text']} <span aria-hidden="true">&rarr;</span>
                  </Link>
                </p>
              )}
          </div>
        ) : (
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              There were {msg.length} errors with your submission
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <ul className="list-disc pl-5 space-y-1">
                {msg.map(m => (
                  <li>{m}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}