import DateTime from 'luxon/src/datetime.js'

/* This example requires Tailwind CSS v2.0+ */
export default function Subscription({subscription}) {
  const Paddle = window.Paddle;
  return (
    <div className="bg-white shadow sm:rounded-lg mb-10">
      <div className="px-4 py-5 p-6 flex-col flex lg:flex-row justify-between">
        <div className='mb-10 lg:mb-0 max-w-xl text-center lg:text-left'>
          <h3 className="text-lg leading-6 font-medium text-gray-900">{`${subscription.plan.name} plan`}</h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              {`${subscription.currency} $${subscription.unit_price}.00 per month`}
            </p>
          </div>
        </div>
        <div className='mb-10 lg:mb-0 max-w-xl text-center lg:text-left'>
          <h4 className="text-md leading-6 font-sm text-gray-900">{`Last charge on`}</h4>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              {DateTime.fromISO(subscription.event_time).toLocaleString(DateTime.DATE_FULL)}
            </p>
          </div>
        </div>
        <div className='mb-10 lg:mb-0 max-w-xl text-center lg:text-left'>
          <h3 className="text-md leading-6 font-sm text-gray-900">{`Next billing date`}</h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              {DateTime.fromISO(subscription.next_bill_date).toLocaleString(DateTime.DATE_FULL)}
            </p>
          </div>
        </div>
        <div className="sm:mt-5 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-mypurple hover:bg-myorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm mr-5 mb-5 sm:mb-0"
            onClick={(e) => {
              e.preventDefault();
              Paddle.Checkout.open({
                override: subscription.update_url
              })
            }}
          >
            Update payment details
          </button>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-mypurple hover:bg-myorange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            onClick={(e) => {
              e.preventDefault();
              Paddle.Checkout.open({
                override: subscription.cancel_url,
                success: 'https://console.unwrangle.com/billing'
              })
            }}
          >
            Cancel subscription
          </button>
        </div>
      </div>
    </div>
  )
}
