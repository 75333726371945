import { useEffect, useState, useCallback } from 'react';
import PlaygroundForm from '../components/PlaygroundForm';
import HomeHero from '../components/HomeHero';
import Skeleton from '../components/Skeleton';
import api from '../utils/api';
import store from '../utils/store';
import LoadingPage from './LoadingPage';

import './Home.css';

export default function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboard, setDashboard] = useState(null);
  const [requestCounter, setRequestCounter] = useState(0);

  const getAccessToken = useCallback(() => store.getItem('accessToken'), []);

  const handleRequestRefresh = useCallback(() => {
    setRequestCounter(prevCounter => prevCounter + 1);
  }, []);

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const accessToken = getAccessToken();
        if (!accessToken) {
          throw new Error('No access token found');
        }
        const dashboard = await api.retrieveDashboardData(accessToken);
        setDashboard(dashboard);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        // Handle error (e.g., redirect to login page)
      } finally {
        setIsLoading(false);
      }
    }

    getDashboardData();
  }, [requestCounter, getAccessToken]);

  useEffect(() => {
    return function cleanup() {
      setDashboard(null);
    }
  }, []);

  return (isLoading ? (
    <LoadingPage />
  ) : (
    <Skeleton email={dashboard.email}>
      <HomeHero dashboard={dashboard} />
      <PlaygroundForm refreshCredits={handleRequestRefresh} apiKey={dashboard.token} />
    </Skeleton>
  ))
}