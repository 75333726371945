import { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  useLocation
} from 'react-router-dom';
import logo from '../assets/images/logo.png';
import ErrorAlert from "../components/ErrorAlert";
import InfoAlert from "../components/InfoAlert";
import SuccessAlert from "../components/SuccessAlert";
import api from "../utils/api";
import store from "../utils/store";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}



export default function VerifyEmail() {

  let query = useQuery();

  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  const key = query.get('key');

  const handleVerification = async (key) => {
    try {
      await api.verifyEmail(key);
    } catch(e) {
      setErrorMsg(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleVerification(key);
    }, 1000)
  }, [key]);

  if (store.getItem('accessToken')) {
    return <Navigate to='/' />
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <Link to='/'>
            <img
              className="mx-auto h-12 w-auto"
              src={logo}
              alt="Unwrangle Logo"
            />
          </Link>
        </div>
      </div>
      <div className="max-w-xl w-full space-y-8 p-10">
        {isLoading ? (
          <div className="animate-pulse">
            <InfoAlert msg={'Awaiting email verification...'} />
          </div>
        ) : (
          errorMsg ? (
            <ErrorAlert msg={errorMsg} link={{'path': '/resend-verification', 'text': 'Resend'}} />
          ) : (
            <SuccessAlert 
              msg={`Thanks for verifying your email address ${query.get('email')}`}
              link={{'path': '/login', 'text': 'Login'}}
            />
          )
        )}
      </div>
    </div>
  )



}