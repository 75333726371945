import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ScraperHero from '../components/ScraperHero';
import ReviewJobs from '../components/ReviewJobs';
import ReviewGroups from '../components/ReviewGroups'; // Add new component for Groups view
import Skeleton from '../components/Skeleton';
import LoadingPage from './LoadingPage';
import JobModal from '../components/JobModal';
import ExportModal from '../components/ExportModal';
import RerunDialog from '../components/RerunDialog';
import { Squares2X2Icon, AdjustmentsHorizontalIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import api from '../utils/api';
import store from '../utils/store';
import analytics from '../utils/analytics';

export default function Scrapers() {
  const { view: viewParam } = useParams();
  const [view, setView] = useState(viewParam || 'batches');
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [jobs, setJobs] = useState([]);
  const [groups, setGroups] = useState([]);
  const [dashboard, setDashboard] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [isRerunDialogOpen, setIsRerunDialogOpen] = useState(false);
  const [selectJob, setSelectJob] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();

  async function listData(page=1) {
    const accessToken = store.getItem('accessToken');
    const identifier = store.getItem('identifier');
    try {
      const dashboard = await api.retrieveDashboardData(accessToken);
      const jobsData = await api.listBatchesAndJobs(accessToken, page);
      if (identifier) {
        analytics.track('View Main');
        analytics.identify(identifier);
        analytics.people.set({
          'plan': dashboard.plan_name,
          'num_jobs': jobsData.count,
          'credits': dashboard.credits.amt
        })
      }
      setJobs(jobsData.results);
      setDashboard(dashboard);
      setTotalPages(Math.ceil(jobsData.count / 10));
    } catch(e) {
      setErrorMsg(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  async function listBatches(page=1) {
    try {
      const jobsData = await api.listBatchesAndJobs(store.getItem('accessToken'), page);
      setJobs(jobsData.results);
    } catch(e) {
      setErrorMsg(e.message);
    }
  }

  async function listJobs(page=1) {
    try {
      const jobsData = await api.listJobs(store.getItem('accessToken'), page);
      console.log(jobsData.results);
      setJobs(jobsData.results);
    } catch(e) {
      setErrorMsg(e.message);
    }
  }

  async function listGroups() {
    try {
      const groupsData = await api.listGroups(store.getItem('accessToken'));
      setGroups(groupsData);
    } catch (e) {
      setErrorMsg(e.message);
    }
  }

  const handleRerunGroup = async () => {
    if (selectedGroupId) {
      try {
        const accessToken = store.getItem('accessToken');
        await api.rerunGroup(accessToken, selectedGroupId);
        setIsRerunDialogOpen(false);
        // Refresh the job list after rerunning the group
        await listBatches(currentPage);
      } catch (error) {
        console.error('Error rerunning group:', error);
        setErrorMsg('Failed to rerun the group. Please try again.');
      }
    }
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === '/scrapers/jobs') {
      setView('jobs');
    } else if (path === '/scrapers/groups') {
      setView('groups');
    } else {
      setView('batches');
    }
  }, [location]);

  useEffect(() => {
    if (view === 'batches') {
      navigate('/scrapers');
    } else {
      navigate(`/scrapers/${view}`);
    }
  }, [view, navigate]);

  useEffect(() => {
    if (view === 'batches') {
      listData();
    } else if (view === 'jobs') {
      listJobs();
    } else if (view === 'groups') {
      listGroups();
    }
    return function cleanup() {
      setJobs([]);
      setGroups([]);
    };
  }, [view]);

  useEffect(() => {
    if (!isOpen || !isRerunDialogOpen) {
      if (view === 'batches') {
        listBatches(currentPage);
      } else if (view === 'jobs') {
        listJobs(currentPage);
      } else if (view === 'groups') {
        listGroups();
      }
    }
  }, [isOpen, isRerunDialogOpen])

  useEffect(() => {
    if (selectJob) {
      setIsExportOpen(true);
    }
  }, [selectJob])

  useEffect(() => {
    if (view === 'batches') {
      listBatches(currentPage);
    } else if (view === 'jobs') {
      listJobs(currentPage);
    } else if (view === 'groups') {
      listGroups();
    }
  }, [currentPage])

  return (isLoading ? (
      <LoadingPage />
    ) : errorMsg ? (
      <div>Error: {errorMsg}</div>
    ) : (
      <Skeleton email={dashboard.email}>
        <JobModal open={isOpen} setOpen={(v) => { setIsOpen(v); }} />
        <ScraperHero dashboard={dashboard} toggleOpen={() => { setIsOpen(!isOpen); }} />
        <div className="flex items-center ml-2 mb-4">
          <Squares2X2Icon className="h-4 w-4 text-gray-500 mr-1" />
          <span className="mr-1 text-sm text-gray-500">Views:</span>
          <button
            className={`mr-2 text-sm ${
              view === 'jobs' ? 'text-mypurple' : 'text-gray-500'
            } hover:text-myorange`}
            onClick={() => setView('jobs')}
          >
            Jobs
          </button>
          <span className="mr-2 text-sm text-gray-500">|</span>
          <button
            className={`mr-2 text-sm ${
              view === 'batches' ? 'text-mypurple' : 'text-gray-500'
            } hover:text-myorange`}
            onClick={() => setView('batches')}
          >
            Batches
          </button>
          <span className="mr-2 text-sm text-gray-500">|</span>
          <button
            className={`mr-4 text-sm ${
              view === 'groups' ? 'text-mypurple' : 'text-gray-500'
            } hover:text-myorange`}
            onClick={() => setView('groups')}
          >
            Groups
          </button>
          <button className="flex items-center mr-4 text-sm text-gray-500 hover:text-myorange">
            <AdjustmentsHorizontalIcon className="h-4 w-4 mr-1" />
            Filters
          </button>
          <button className="flex items-center text-sm text-gray-500 hover:text-myorange">
            <MagnifyingGlassIcon className="h-4 w-4 mr-1" />
            Search
          </button>
        </div>
        {view === 'batches' && (
          <ReviewJobs
            jobs={jobs}
            setExportJob={(v) => {
              setSelectJob(v);
              setIsExportOpen(true);
            }}
            currentPage={currentPage}
            setCurrentPage={(v) => setCurrentPage(v)}
            totalPages={totalPages}
            onRerunGroup={(groupId) => {
              setSelectedGroupId(groupId);
              setIsRerunDialogOpen(true);
            }}
          />
        )}
        {view === 'jobs' && (
          <ReviewJobs
            jobs={jobs}
            setExportJob={(v) => {
              setSelectJob(v);
              setIsExportOpen(true);
            }}
            currentPage={currentPage}
            setCurrentPage={(v) => setCurrentPage(v)}
            totalPages={totalPages}
            onRerunGroup={(groupId) => {
              setSelectedGroupId(groupId);
              setIsRerunDialogOpen(true);
            }}
          />
        )}
        {view === 'groups' && <ReviewGroups groups={groups} />}
        <ExportModal open={isExportOpen} setOpen={(v) => { setIsExportOpen(v); }} jobId={selectJob} />
        {view === 'batches' && <RerunDialog 
          open={isRerunDialogOpen} 
          setOpen={setIsRerunDialogOpen} 
          jobGroup={jobs.find(job => job.group_id === selectedGroupId)}
          onConfirm={handleRerunGroup}
        />}
      </Skeleton>
    )
  )
}