/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from 'react'
import { DialogTitle, Dialog, DialogPanel, DialogBackdrop } from '@headlessui/react'
import { InboxArrowDownIcon } from '@heroicons/react/24/outline'
import store from '../utils/store';
import api from '../utils/api';
import { timeout, verifyToken } from '../utils/utils';
import ErrorAlert from './ErrorAlert';
import SuccessAlert from './SuccessAlert';

export default function ExportModal({open, setOpen, jobId}) {
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('')

  useEffect(() => {
    return function cleanup() {
      setErrorMsg('');
      setSuccessMsg('');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await verifyToken();
      const accessToken = store.getItem('accessToken');
      const msg = await api.exportResultsEmail(accessToken, jobId);
      setSuccessMsg(msg);
      await timeout(2000);
      setErrorMsg('');
      setSuccessMsg('');
      setOpen(false);
    } catch(e) {
      setErrorMsg(e.message);
      await timeout(2000);
      setErrorMsg('');
    }
  }

  const cancelButtonRef = useRef(null);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-start sm:p-0 mt-5">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <InboxArrowDownIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Export Results
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Would you like to export the results for job <strong>ID {jobId}</strong> to you email account? Note that the link
                      will be valid for 3 days.
                    </p>
                  </div>
                  {errorMsg && (
                    <div className='mt-2'>
                      <ErrorAlert msg={errorMsg} />
                    </div>
                  )}
                  {successMsg && (
                    <div className='mt-2'>
                      <SuccessAlert msg={successMsg} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:cursor-not-allowed"
                onClick={handleSubmit}
                disabled={errorMsg || successMsg}
              >
                Send
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => setOpen(false)}
                ref={cancelButtonRef}
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}