import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivRoute from './components/PrivRoute';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Home from './pages/Home';
import Scrapers from './pages/Scrapers';
import Billing from './pages/Billing';
import ResendVerification from './pages/ResendVerification';
import ResetPassword from './pages/ResetPassword';
import Signup from './pages/Signup';
import VerifyEmail from './pages/VerifyEmail';
import './App.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<PrivRoute component={Home} />} />
        <Route exact path="/scrapers" element={<PrivRoute component={Scrapers} />} />
        <Route path="/scrapers/:view" element={<PrivRoute component={Scrapers} />} />
        <Route path="/logout" element={<PrivRoute component={Logout} />} />
        <Route path="/billing" element={<PrivRoute component={Billing} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/resend-verification" element={<ResendVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/password/reset/confirm/:uid/:token" element={<ResetPassword />} />
      </Routes>
    </Router>
  );
};

export default App;