import { useEffect, useState } from 'react';
import Skeleton from '../components/Skeleton';
import Plans from '../components/Plans';
import Subscription from '../components/Subscription';
import api from '../utils/api';
import store from '../utils/store';
import LoadingPage from './LoadingPage';
import analytics from '../utils/analytics';

export default function Billing() {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboard, setDashboard] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const accessToken = store.getItem('accessToken');

  const listSubscriptions = async () => {
    const s = await api.listSubscriptions(accessToken);
    analytics.track('View billing');
    if (s.length >= 1) {
      setSubscription(s[0])
    }
  }

  const getEmail = async () => {
    const dashboard = await api.retrieveDashboardData(accessToken);
    setDashboard(dashboard)
  }

  async function apiCalls() {
    await getEmail();
    await listSubscriptions();
    setIsLoading(false);
  }
  
  useEffect(() => {
    apiCalls();
  }, [])

  return (
    isLoading ? (
        <LoadingPage />
      ) : (
        <Skeleton email={dashboard.email}>
          {subscription ? (
            <Subscription subscription={subscription} />
          ) : (
            <Plans />
          )}
        </Skeleton>
      )
  )
}