import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import store from '../utils/store';
import api from '../utils/api';
import { verifyToken } from '../utils/utils';

import ErrorAlert from './ErrorAlert';
import LoadingPage from '../pages/LoadingPage';

export default function PrivRoute({ component: Component, ...rest }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    const checkToken = async () => {
      try {
        await verifyToken();
        setIsLoading(false);
      } catch (e) {
        setErrorMsg(e.message);
      }
    };

    checkToken();

    return function cleanup() {
      setIsLoading(true);
      setIsRefreshed(false);
      setErrorMsg('');
    };
  }, [isRefreshed]);

  if (store.getItem('accessToken')) {
    if (isLoading) {
      return <LoadingPage />;
    } else if (errorMsg) {
      return <ErrorAlert msg={errorMsg} />;
    } else {
      return <Component {...rest} />;
    }
  } else {
    return <Navigate to="/login" state={{ from: rest.location }} replace />;
  }
}