import { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import api from '../utils/api';
import logo from '../assets/images/logo.png';
import store from '../utils/store';

import ErrorAlert from '../components/ErrorAlert';
import SuccessAlert from '../components/SuccessAlert';

export default function ResetPassword() {
  const { uid, token } = useParams();

  const [pwd, setPwd] = useState('');
  const [cnfrmPwd, setCnfrmPwd] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    setIsDisabled(!pwd || !cnfrmPwd);
  }, [pwd, cnfrmPwd]);

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      setIsFormLoading(true);
      await api.resetPassword(uid, token, pwd, cnfrmPwd);
      setIsDone(true);
    } catch(e) {
      setErrorMsg(e.message.split('|'));
    } finally {
      setIsFormLoading(false);
    }
  }

  if (store.getItem('accessToken')) {
    return <Navigate to='/' />
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Link to='/'>
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="Unwrangle Logo"
          />
        </Link>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-mypurple">
          Reset Password
        </h2>
        {!isDone && (
          <p className="mt-2 text-center text-sm text-gray-600">
            Submit the form below to set a new password
          </p>
        )}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        {!isDone ? (
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" method="POST" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mypurple focus:border-mypurple sm:text-sm"
                    value={pwd}
                    onChange={(event) => setPwd(event.target.value)}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <div className="mt-1">
                  <input
                    id="confirm-password"
                    name="confirm-password"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mypurple focus:border-mypurple sm:text-sm"
                    value={cnfrmPwd}
                    onChange={(event) => setCnfrmPwd(event.target.value)}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mypurple disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={isDisabled || isFormLoading}
                >
                {isFormLoading ? (
                  <svg className="animate-spin h-5 w-5 text-white" viewBox="0 0 24 24" file="none">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  'Submit'
                )}
                </button>
              </div>
              {errorMsg && (
                <ErrorAlert msg={errorMsg} />
              )}
            </form>
          </div>
        ) : (
          <SuccessAlert msg='Your password has been reset' link={{'path': '/login', 'text': 'Login to continue'}} />
        )}
      </div>
    </div>
  )
}