import { LockClosedIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';

import api from '../utils/api';
import logo from '../assets/images/logo.png';
import store from '../utils/store';
import withRedirect from '../hoc/withRedirect';

import ErrorAlert from '../components/ErrorAlert';
import analytics from '../utils/analytics';


function Login({setRedirect}) {

  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setIsDisabled(!email || !pwd);
    return function cleanup() {
      setIsFormLoading(false);
    }
  }, [email, pwd]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsFormLoading(true);
      const identifier = await api.login(email, pwd);
      analytics.identify(identifier);
      analytics.track('Login Successful');
      analytics.people.set({'email': email})
      setRedirect(true);
    } catch(e) {
      analytics.track('Login Error', {
        'msg': e.message
      })
      setErrorMsg(e.message)
    } finally {
      setIsFormLoading(false);
    }
  }
  if (store.getItem('accessToken')) {
    return <Navigate to='/' />
  }
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <Link to='/'>
            <img
              className="mx-auto h-12 w-auto"
              src={logo}
              alt="Unwrangle Logo"
            />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <Link to="/signup" className="font-medium text-mypurple hover:text-myorange">
              sign up to get started
            </Link>
          </p>
        </div>
        <form className="mt-8 space-y-6" method="POST" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-mypurple focus:border-mypurple focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-mypurple focus:border-mypurple focus:z-10 sm:text-sm"
                placeholder="Password"
                value={pwd}
                onChange={(event) => setPwd(event.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="h-4 w-4 text-mypurple focus:ring-myorange border-gray-300 rounded"
              />
              <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <Link to="/forgot-password" className="font-medium text-mypurple hover:text-myorange">
                Forgot your password?
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isDisabled || isFormLoading}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-white group-hover:text-gray-300" aria-hidden="true" />
              </span>
              {isFormLoading ? (
                <svg className="animate-spin h-5 w-5 text-white" viewBox="0 0 24 24" file="none">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : (
                'Sign in'
              )}
            </button>
          </div>
          {errorMsg && (
            <ErrorAlert 
              msg={errorMsg} 
              link={
                errorMsg === 'E-mail is not verified.' ? 
                {'path': '/resend-verification', 'text': 'Resend Verification Email'} 
                : 
                null
              }
            />
          )}
        </form>
      </div>
    </div>
  )
}

export default withRedirect(Login);