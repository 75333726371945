import api from './api';
import store from './store';

export function getCookie(cookiename) {
  // Get name followed by anything except a semicolon
  var cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
  // Return everything after the equal sign, or an empty string if the cookie name not found
  return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
}


export function parseErrorMessage(jsonResponse, usePipe=false) {
  if (Object.keys(jsonResponse).length === 1) {
    if (jsonResponse['detail']) {
      return jsonResponse['detail'];
    } else {
      const val = jsonResponse[Object.keys(jsonResponse)[0]];
      if (Array.isArray(val) && !usePipe) {
        return val.join(" ");
      } else if (Array.isArray(val)) {
        return val.join("|");
      }
      return val;
    }
  }
  // TODO: need to complete this function
  return "More than 1 error";
}

export function unauthRes(res) {
  if (res.status === 401 && res.statusText === 'Unauthorized') {
    throw new Error('The security token in your request has expired. Please refresh');
  }
}

export async function hashString(str) {
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data); // Using SHA-256 instead of MD5 for better security
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // Convert bytes to hex string
  return hashHex;
}

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function verifyToken() {
  console.log('utils');
  let attempts = 0;
  const maxAttempts = 5;

  while (attempts < maxAttempts) {
    try {
      // Get the latest access token from the store before verification
      const accessToken = store.getItem('accessToken');
      const isValid = await api.verifyToken(accessToken);
      if (isValid) {
        return; // Exit the function if the token is valid
      } else {
        const newAccessToken = await api.refreshToken(store.getItem('refreshToken'));
        attempts++;
      }
    } catch (e) {
      store.clear();
      throw new Error('Something went wrong. Please refresh and sign in again.');
    }
  }

  // If we reach here, it means all attempts failed
  throw new Error('Unable to refresh token after multiple attempts. Please sign in again.');
}